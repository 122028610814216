import * as React from "react"

import Header from "../components/Header";
import "../sass/main.scss";

import bookImg from '../images/original.jpg';

// markup
const IndexPage = () => {
  return (
    <div className="main">
      <Header />
      <div className="container-fluid main-content">
        <div className="row flex-row-reverse">
          <div className="col-sm-6 bg-light content-pane pane-image justify-content-center align-items-center d-flex">
            <img src={bookImg} />
          </div>
          <div className="col-sm-6 bg-info content-pane justify-content-center align-items-center d-flex flex-column-reverse flex-sm-column">
            <blockquote>
              <p><strong>לידה נאמן ישראלי</strong>, כותבת הסיפור המרתק, מתארת את חיי המשפחה הנפלאים תחת משטרו של מוחמד רזא שאה והחיים בצל האסלאם, עד התקופה הקשה שבה הקרקע רעדה תחת רגליהם, ומולדתם חוותה הפיכה. </p>
              <p><strong>זָאלזָלָאק</strong> מתאר לא רק את סיפור המשפחה וסיפור בריחתה מאיראן, אלא גם את הסיפור ההיסטורי המרתק של יהודי איראן, על רקע נופים עוצרי נשימה, תוך התייחסות לחיי היום-יום שלהם לפני ואחרי המהפכה, אורחות חייהם ומנהגיהם המיוחדים, אהבתם העזה למולדתם איראן, ומעל הכול – מסירותם והשתייכותם הבלתי מעורערת לארץ האבות, ארץ ישראל. כגוף האדם, הלב הוא המולדת איראן, והמוח הוא הארץ המיועדת ישראל, ולא ניתן להפריד בין השניים.</p>
              <p>זהו ספר סוחף ומותח על מסע משנה חיים – רווי כאב, תלאות, אכזבות, אכזריות, ויחד עימם גם רווי אופטימיות ותקווה לחיים חדשים.</p>
              <p>זהו ספר שהוא למעשה מסמך חיים מתעד, המתאר באופן יוצא דופן יופיו כאב המשולבים זה בזה. </p>
              <p>המחברת <strong>לידה נאמן ישראלי</strong> מחייה מחדש את אותן הדמויות, והקוראים מרגישים את סיפור חייה המפותל.</p>
            </blockquote>
            <button type="button" className="btn btn-dark btn-lg col-12 col-sm-6 mt-3 mb-4">לרכישת הספר</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
