import React from "react";

export default () => {
  return (
    <div className="header bg-light shadow p-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex">
            <h1 className="d-flex align-items-center flex-fill">זָאלזָלָאק</h1>
          </div>
          <div className="col d-flex">
            <div className="d-flex justify-content-end align-items-center flex-fill">לידה נאמן ישראלי</div>
          </div>
        </div>
      </div>
    </div>
  );
};
